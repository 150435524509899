.generaic_page_content {
  font-family: "IBM Plex Sans" !important;
  .light_bg {
    background-color: #f6f9fc !important;
  }
  .generic_bg_img {
    background: radial-gradient(
      86.49% 86.49% at 50% 44.95%,
      #171f2c 0%,
      #0c0f15 100%
    );
    overflow: hidden;
  }
  .top_section_spacing {
    margin-top: -75px;
    @media (max-width: 991.98px) {
      margin-top: 0px;
      padding: 0 30px;
      text-align: center;
    }
  }
  .first-section {
    padding: 3rem 0 5% !important;
    position: relative;
    @media (max-width: 1024px) {
      padding-top: 6rem !important;
    }
    @media (max-width: 991.98px) {
      padding-top: 10rem !important;
      text-align: center !important;
    }

    .main-text {
      font-size: 40px;
      line-height: 52px;
      font-weight: 500;
      @media (max-width: 1600.98px) {
        font-size: 36px !important;
        line-height: 45px !important;
      }
      @media (max-width: 1150.98px) {
        font-size: 34px !important;
        line-height: 43px !important;
      }
      @media (max-width: 991.98px) {
        font-size: 40px !important;
        line-height: 52px !important;
      }
      @media (max-width: 687.98px) {
        font-size: 35px !important;
        line-height: 45px !important;
      }
      @media (max-width: 467.98px) {
        font-size: 30px !important;
        line-height: 39px !important;
      }
      @media (max-width: 375.98px) {
        font-size: 27px !important;
        line-height: 37px !important;
        margin: 5px 0;
      }
    }
    .paragraph_text {
      font-size: 24px;
      font-weight: 300;
      line-height: 36px;
      text-align: left;
      @media (max-width: 1300.98px) {
        font-size: 22px !important;
        line-height: 33px !important;
      }
      @media (max-width: 1150.98px) {
        font-size: 21px !important;
        line-height: 30px !important;
      }
      @media (max-width: 991.98px) {
        font-size: 24px !important;
        line-height: 36px !important;
        text-align: center;
      }
      @media (max-width: 687.98px) {
        font-size: 22px !important;
        line-height: 33px !important;
      }
      @media (max-width: 350px) {
        font-size: 20px !important;
        line-height: 28px !important;
      }
    }
  }
  .bgblack {
    background: #10151d;
  }
  .header_content {
    padding: 10px 100px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    @media (max-width: 767.98px) {
      padding: 10px 50px;
    }
    @media (max-width: 425.98px) {
      padding: 10px;
    }
    .EP_logo {
      width: 220px;
      height: 75px;
      @media (max-width: 1199.98px) {
        margin-left: 10px;
      }
      @media (max-width: 500.98px) {
        img {
          width: 95%;
          height: 95%;
        }
      }
      @media (max-width: 425.98px) {
        left: 20px;
      }
    }
    .contact_section {
      padding: 20px;
      color: white;

      .help_texts {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: end;
      }
      .contact_texts {
        font-size: 17px;
        font-weight: 600;
        line-height: 25px;
        text-align: right;
        a {
          text-decoration: none !important;
        }
      }
      .call_btn {
        background: #0155b7;
        border-radius: 20px;
        width: 40px;
        height: 40px;
        padding: 0;
        top: -5px;
        min-width: unset;
      }
    }
  }
  .ep__img-block {
    // width: 100%;
    height: 841px;
    position: relative;
    margin-left: 50px;
    @media (max-width: 1600px) {
      margin-left: 60px;
    }
    @media (max-width: 1335px) {
      margin-left: 75px;
    }
    @media (max-width: 1199px) {
      margin-left: 10px;
    }
    @media (max-width: 991.98px) {
      margin-left: 0;
    }
    @media (max-width: 875px) {
      margin-left: 0;
      margin-top: 50px;
    }
    .radio_img {
      width: 100%;
      object-fit: contain;
      position: absolute;
      left: 0;
      // right: 20px;
      top: 60px;
      z-index: 9;
      @media (min-width: 1545.98px) {
        width: 95%;
        margin-left: 0px;
      }
      @media (min-width: 1633.98px) {
        width: 92%;
      }
      @media (min-width: 1690.98px) {
        width: 90%;
      }
      @media (min-width: 1733.98px) {
        width: 85%;
      }
      @media (min-width: 1800.98px) {
        width: 80%;
      }
      @media (min-width: 1833.98px) {
        width: 75%;
      }
      @media (min-width: 1900.98px) {
        width: 70%;
        margin-left: 30px;
      }
      @media (min-width: 2000.98px) {
        width: 65%;
        margin-left: 30px;
      }
      @media (max-width: 1250.98px) {
        width: 100%;
        margin-left: 0px;
      }
      @media (max-width: 991.98px) {
        width: 100%;
      }
      @media (max-width: 769.98px) {
        object-fit: cover;
        width: 90%;
        margin: 0 4%;
      }
      @media (max-width: 530.98px) {
        width: 100%;
        margin: 0;
      }
      .box-container {
        border-radius: 10px;
      }
    }
  }
  .ep__bg-logo {
    position: absolute;
    top: 0;
    bottom: auto;
    left: auto;
    @media (max-width: 1500px) {
      right: -50px;
    }
    @media (max-width: 1280px) {
      right: -115px;
    }
    @media (max-width: 991.98px) {
      top: auto;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      right: 0px;
      img {
        // width: 100%;
        height: 906px;
      }
    }
    @media (max-width: 667.98px) {
      img {
        height: 860px;
      }
    }
    @media (max-width: 500.98px) {
      bottom: 100px;
      img {
        height: 500px;
      }
    }
  }
  .buttons_section {
    display: flex;
    margin-top: 40px;

    justify-content: center;

    .risk_button {
      padding: 15px 25px;
      margin-right: 12px;
      border-radius: 29px;
      gap: 10px;
      background: #15be53;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-align: center;
      color: #0d120b;
      @media (max-width: 1362px) {
        font-size: 14px;
      }
      @media (max-width: 1277px) {
        font-size: 12px;
        line-height: 18px;
        padding: 15px 20px;
      }
      @media (max-width: 991.98px) {
        font-size: 16px;
        line-height: 20px;
        padding: 15px 27px;
        margin-bottom: 25px;
      }
      @media (max-width: 600.99px) {
        margin-top: 10px;
      }
      @media (max-width: 480.99px) {
        width: 85%;
        font-size: 14px;
        line-height: 18px;
        margin-left: 20px;
      }
    }
    .discount_button {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-align: center;
      padding: 15px 25px;
      border-radius: 29px;
      gap: 10px;
      color: black;
      background: #ffffff;
      &:not(.disable):hover {
        background: #ffffff;
      }
      @media (max-width: 1362px) {
        font-size: 14px;
      }
      @media (max-width: 1259px) {
        font-size: 12px;
        line-height: 18px;
      }
      @media (max-width: 1188.98px) {
        padding: 15px 20px;
      }
      @media (max-width: 991.98px) {
        font-size: 16px;
        line-height: 20px;
        padding: 15px 27px;
      }

      @media (max-width: 600.99px) {
        padding: 15px;
        margin-top: 10px;
      }
      @media (max-width: 480.99px) {
        width: 100%;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  .pp_title {
    font-size: 30px;
    font-weight: 600;
    line-height: 39px;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 28px;
      line-height: 37px;
    }
  }
  .alert_section {
    padding-top: 50px;
    padding-bottom: 50px;
    .it_works {
      padding-top: 50px;
    }
    .how_its_works_title {
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      text-align: center;
      @media (max-width: 600px) {
        font-size: 28px;
        line-height: 35px;
      }
    }
    .how_its_works_texts {
      font-size: 20px;
      font-weight: 300;
      line-height: 30px;
      text-align: center;
      @media (max-width: 600px) {
        font-size: 18px;
        line-height: 25px;
      }
    }
    .steps_wrapper {
      .steps {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #0d120b;
        background: rgba(21, 190, 83, 0.3);
        width: 46px;
        height: 46px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 575.98px) {
          text-align: center;
        }
      }
      .number_alignment {
        text-align: center;
        padding: 0 45%;
      }
      .info_blocks {
        background-color: white !important;
        padding: 15px;
        border-radius: 10px;
        //   border: 1px solid #dee3ea;
        height: 260px;
        @media (max-width: 572.98px) {
          text-align: center;
        }
      }
      .header_text {
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        text-align: center;
        @media (max-width: 1341.98px) {
          margin-bottom: 20px !important;
        }
      }
      .paragraph_text {
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        text-align: center;
      }
      .adjust_spacing {
        .sec1,
        .sec3 {
          @media (max-width: 1341.98px) {
            margin-top: 42px !important;
          }
          @media (max-width: 991.98px) {
            margin-top: 1.5rem !important;
          }
        }
        .sec1 {
          @media (max-width: 792.98px) {
            margin-top: 42px !important;
          }
          @media (max-width: 575.98px) {
            margin-top: 1.5rem !important;
          }
        }
        .sec2 {
          @media (max-width: 1341.98px) {
            margin-top: 0px !important;
          }
          @media (max-width: 991.98px) {
            margin-top: 0px !important;
          }
        }
      }
    }
  }

  .host_last_section {
    padding: 0 150px;
    @media (max-width: 1150.98px) {
      padding: 0 100px;
    }
    @media (max-width: 1050.98px) {
      padding: 0 50px;
      // margin-bottom: 30px;
    }
    @media (max-width: 880.98px) {
      margin-bottom: 0px;
      padding: 0 50px;
    }
    @media (max-width: 700.98px) {
      padding: 0px;
    }

    .main-text {
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      text-align: center;
      @media (max-width: 600px) {
        font-size: 28px;
        line-height: 37px;
      }
    }

    .caption_sections {
      justify-content: center;
    }
    .captions {
      font-size: 24px;
      font-weight: 300;
      line-height: 36px;
      text-align: center;
      @media (max-width: 1425px) {
        font-size: 22px !important;
        line-height: 36px !important;
      }
      @media (max-width: 1362px) {
        font-size: 20px !important;
        line-height: 36px !important;
      }
      @media (max-width: 1259px) {
        padding: 8px;
        line-height: 31px !important;
      }
      @media (max-width: 350px) {
        // font-size: 20px !important;
        line-height: 28px !important;
      }
    }

    .ep__promocode-sec {
      margin: 0 auto;
      background: #171e2b;
      display: flex;
      flex-wrap: wrap;
      background-color: #1a1a1a;
      border-radius: 10px;
      color: #fff;
      width: 100%;
      box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
      // height: 388px;
      position: relative;
      align-items: center;
      background-image: url("../../assets/icons/EP_bg_logo.webp");
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      background-position: center;
      @media (max-width: 991.98px) {
        // position: static;
        // max-width: 100%;
        // height: 300px;
        // margin: 0 auto;
        // height: 100%;
        // background-position: bottom center;
      }
    }
    .ep__promocode-desc {
      padding: 60px 8%;
      box-sizing: border-box;
      @media (max-width: 599.98px) {
        padding: 30px 5%;
      }
    }
    .ep__promocode-wrap {
      @media (max-width: 991.98px) {
        max-width: 100%;
        // padding: 50px 0 0;
      }
    }
  }
  .call_on {
    font-size: 17px;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
    margin-top: 20px;
    a {
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: auto;
      color: #ffffff;
    }
  }
}
