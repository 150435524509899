body {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f9fc !important;
  /* overflow-y: auto !important; */
  padding-right: 0 !important;
}

.MuiTypography-root {
  word-break: break-word !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#chartdiv {
  width: 100%;
  max-width: 100%;
  height: 500px;
}
img {
  max-width: 100%;
  height: auto;
}

.logo-custom-width {
  max-width: 100px;
}

.H1 {
  font-size: 40px;
  line-height: 52px;
}
.date-picker-disabled {
  pointer-events: none;
  /* opacity: 1; */
}
.paragraph_text {
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}
.pricing_paragraph_texts {
  font-size: 22px;
  line-height: 30px;
  text-align: center;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.mx-150 {
  padding-left: 150px;
  padding-right: 150px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pt-150 {
  padding-top: 150px;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700;
}

.fw-900 {
  font-weight: 900;
}

.protection_bg {
  background: #15be53;
}

.price_clr {
  color: #0071c3 !important;
}
.font_16 {
  font-size: 16px !important;
}

.text-blue {
  color: #0395ff !important;
}

/* NAVBAR CSS:: START */

.navbar-spacing {
  padding-left: 100px;
  padding-right: 100px;
}
.title-risk-dropdown .MuiPaper-elevation {
  background: #ffffff;
}
.mobile-accordion,
.navbar-menu-texts {
  background: none !important;
  border: solid 1px #ffffff5c;
  color: white !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-decoration: none;
  padding: 0px 30px;
  margin-right: 20px !important;
}
.navbar-menu-texts {
  color: black !important;
  padding: 0 10px;
}
.mobile-accordion a,
.mobile-accordion .MuiAccordionSummary-expandIconWrapper {
  color: white !important;
}

.mobile-accordion .MuiAccordionSummary-root {
  padding: 0;
  font-weight: bold;
}

.mobile-accordion .MuiAccordionDetails-root {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#HeaderNavbar {
  transition: all 0.5s ease;
}

#HeaderNavbar.sticky {
  background-color: #333333 !important;
}

.navbar-center {
  flex-grow: 1;
}
.apex-padding {
  margin-bottom: 120px !important;
}
.navitems a {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-decoration: none;
}
.reposrtResultNavbar {
  background: #3c4c58 !important;
}
#HeaderNavbar.reposrtResultNavbar {
  background: #3c4c58 !important;
}
@media screen and (min-width: 991.98px) and (max-width: 1100px) {
  .navitems a {
    font-size: 12px !important;
  }
}
.highlight_filter_field {
  /* Default styles for the input field */
  input {
    border-radius: 4px;
    font-weight: 500 !important;
  }
  .MuiSelect-outlined {
    border-radius: 4px;
    font-weight: 500 !important;
  }
  fieldset,
  .PhoneInputCountry {
    border: 2px solid #1976d2;
  }
}
.highlight_filter_phone {
  border: 2px solid #1976d2 !important;
  input {
    border-radius: 4px;
    font-weight: 500 !important;
  }
}
/* NAVBAR CSS:: END */

/* React Slick Carousel :: START */

.slick-dots li button:before {
  font-size: 7px;
  line-height: 22px;
  color: #0395ff;
  opacity: 0.3;
}
.slick-dots li {
  width: 15px !important;
}
.slick-dots li.slick-active button:before {
  color: #0155b7;
  opacity: 1;
}
.slick-slider {
  margin: 0 -15px;
}

.slick-slide {
  padding: 10px;
}

/* React Slick Carousel :: END */

/* Testimonial Carousel :: START */

/* Equal Height */

.testimonialWrapper .slick-track {
  display: flex !important;
}
.testimonialWrapper .slick-track .slick-active {
  height: inherit !important;
  opacity: 1 !important;
}
.testimonialWrapper .slick-track .slick-slide {
  height: inherit !important;
  opacity: 0.5;
}

.slick-active:nth-child(4n) {
  opacity: 0.5 !important;
}
.testimonialWrapper .slick-track .slick-slide > div {
  height: 100%;
}
/* Equal Height */

.testimonialWrapper .testimonialCard {
  background: #ffffff;
  border-radius: 10px;
}

.testimonialWrapper .testimonialCard .testimonials .contentWrapper {
  padding: 40px 60px;
  text-align: center;
  background: rgba(1, 85, 183, 1);
  color: #ffffff;
  border-radius: 10px 10px 0 0;
  flex: 1;
}

.testimonialWrapper .testimonialCard .testimonials .contentWrapper img {
  max-width: 50px;
}

.testimonialWrapper
  .testimonialCard
  .testimonials
  .contentWrapper
  img.QuoteStart {
  position: absolute;
  left: -20px;
  top: 0;
}

.testimonialWrapper
  .testimonialCard
  .testimonials
  .contentWrapper
  img.QuoteEnd {
  position: absolute;
  right: -20px;
  bottom: 0;
}

.testimonialWrapper .testimonialCard .testimonials .contentWrapper .authorName {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.05em;
}

.testimonialWrapper
  .testimonialCard
  .testimonials
  .contentWrapper
  .companyName {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
}

.testimonialWrapper .testimonialCard .testimonials .author {
  position: relative;
  margin-top: -30px;
  padding: 0 15px 40px;
  background: rgba(1, 85, 183, 1);
  border-radius: 10px;
}

.testimonialWrapper .testimonialCard .testimonials .author .authorImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  /* border: 10px solid #ffffff; */
}

/* Testimonial Carousel :: END */

.footer_btn,
.footer_btn:hover,
.footer_btn:focus,
.footer_btn:visited,
.footer_btn:active,
.footer_btn:active:hover,
.footer_btn:active:focus {
  padding: 6px 20px;
  color: black;
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  border: none;
  text-decoration: none;
  box-shadow: none;
}

.footer_blue,
.footer_blue:hover,
.footer_blue:focus,
.footer_blue:visited,
.footer_blue:active,
.footer_blue:active:hover,
.footer_blue:active:focus {
  background: #0155b7;
}

.footer_green,
.footer_green:hover,
.footer_green:focus,
.footer_green:visited,
.footer_green:active,
.footer_green:active:hover,
.footer_green:active:focus {
  background: #15be53;
  /* background: #0b652c; */
}

/* USER ADMIN */

#adminHeader .supportContact span,
#adminHeader .supportContact a {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4c524a;
}

.popup_Btn {
  flex-direction: row;
  align-items: flex-start !important;
  padding: 12px 35px !important;
  font-weight: 600 !important;
  gap: 10px;
  border-radius: 29px !important;
  color: #ffffff !important;
  border: none !important;

  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.05em;
  text-transform: uppercase !important;
}

.success_popup_btn {
  background: #15be53 !important;
}

.error_popup_btn {
  background-color: #ff006b !important;
}

.other_popup_btn {
  background-color: #ffffff !important;
  color: black !important;
  border: 1px #9cb8a6 solid !important;
}

.request-a-call-btn {
  background-color: #000 !important;
  color: #fff !important;
  /* border: 1px #9cb8a6 solid !important; */
}

.success-btn {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  /* identical to box height, or 125% */
  background-color: #15be53 !important;
  border-radius: 29px !important;
  letter-spacing: 0.05em !important;
  padding: 15px 40px;
  text-transform: uppercase;
  color: #ffffff;
}
.admin_buttons {
  padding: 10px 40px !important;
  border-color: initial !important;
}
/* to hide country name in profile */
.PhoneInputCountrySelect[disabled] {
  display: none;
}
.autocomplete_error {
  border: #d32f2f 1px solid !important;
}

.autocomplete_error .sign-textField,
.autocomplete_error button,
.autocomplete_error .MuiFormLabel-root.Mui-focused,
.autocomplete_error .MuiFormLabel-root,
.autocomplete_error label,
.password_error .MuiFormLabel-root.Mui-focused,
.password_error .error_color,
.error_color {
  color: #d32f2f !important;
}

/* set height of table and add scroll */
/* .MuiTableContainer-root {
  max-height: 62vh;
  overflow-y: auto;
} */

.cursor-pointer {
  cursor: pointer !important;
}

/* show that cursor is disable */
.cursor-disable {
  pointer-events: unset !important;
  cursor: no-drop !important;
}

.PhoneInputCountrySelectArrow {
  color: #03b2cb;
}

#TryHeaderNavbar {
  transition: all 0.5s ease;
  background-color: #000 !important;
}

#TryHeaderNavbar.sticky {
  background-color: #000 !important;
}

.calendly-popup {
  height: 700px !important;
}

/* for moving recaptcha-badge on the right side */
.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}
.parcel-width {
  width: 880px;
}
.icon-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.icon-outer-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 56px;
  background-color: rgba(3, 149, 255, 0.1);
  border-radius: 50%;
}
.icon-inner-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background-color: rgba(3, 149, 255, 0.2);
  border-radius: 50%;
}
.zoom-modal-wrapper {
  .MuiDialog-paper {
    margin: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
  .MuiDialogContent-root {
    overflow-y: overlay !important;
  }
}
.zoom-modal-wrapper2 .MuiDialog-paper {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
}
.zoom-modal-wrapper.MuiModal-root {
  position: fixed !important;
}
.close-text {
  font-size: 15px;
  color: #15be53 !important;
  font-weight: bold;
  text-align: right;
  position: relative;
  right: 28px;
  top: -6px;
}
.close-text-a {
  font-size: 14px !important;
  color: #15be53 !important;
  font-weight: bold !important;
  /* text-align:right ; */
  text-align: center;
}
.zoom-container {
  margin: 0 auto !important;
  max-width: 1000px;
  width: 100%;
  box-shadow: rgba(52, 75, 91, 0.29) 0px 30px 70px -10px;
}
.zoom-container .form-content-spacing {
  padding: 10px 20px;
}
.zoom-container .plans-content-spacing {
  padding: 10px 30px;
}
.zoom-container .buttons-content-spacing {
  padding: 0px 30px 10px;
}
.no-box-shadow {
  box-shadow: none !important;
}
.fixed-height {
  max-height: calc(100vh - 495px) !important;
  /* max-height: 200px !important; */
}
@media only screen and (max-width: 599.98px) {
  .fixed-height {
    max-height: 100vh;
  }
}
.flex-vertically {
  /* min-width: 100% !important; */
  height: 70% !important;
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: end !important; */
  /* align-items: center; */
}
.signin_button {
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 40px;
  font-weight: 600;
  border-radius: 29px;
  color: #ffffff;
  border: none;
  letter-spacing: 0.05em;
}

#TryHeaderNavbar .navitems .active-on-hover {
  padding-bottom: 10px !important;
  box-sizing: border-box;
}

#TryHeaderNavbar .navitems .active-on-hover:hover {
  box-shadow: inset 0 -2px 0 #02a8e2;
}
/* desktop filter css */
#filter {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  width: 150px;
}
#filter .filter-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #4c524a;
}
#filter .filter-arrow {
  color: #d9d9d9;
}
#filter-options-card {
  width: max-content;
  overflow: visible;
  height: 75px;
  background: #ffffff;
  border-color: #ffffff;
  box-shadow: 0px 1px 0px rgba(52, 75, 91, 0.06);
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px 30px;
}
#filter-options-card .search-text {
  color: #4c524a;
  font-size: 14px;
}
#filter-options-card .filter-options-box {
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
#filter-options-card .filter-options-box div {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}
#filter-options-card .filter-select-one {
  width: 200px;
  /* height: 46px; */
  border-radius: 3px;
}
#filter-options-card .filter-search {
  width: 226px;
  /* height: 46px; */
  border-radius: 3px;
}
#filter-options-card .filter-button {
  height: 40px;
  color: white;
  border-radius: 24px;
  padding: 6px 20px;
  background: #0395ff;
}
#filter-options-card .filter-button:hover {
  background: #007dd7;
}
#filter-options-card .reset-filters {
  color: #ff006b;
  font-size: 14px;
  cursor: pointer;
}
#filter-options-card .reset-filters:hover {
  color: #c80053;
}
.filter-options-carddiv {
  background: #fff;
  border-color: #fff;
  box-shadow: 0px 1px 0px rgba(52, 75, 91, 0.06);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 10px 30px;
}
.filter-options-carddiv .search-text {
  color: #4c524a;
  font-size: 14px;
}
.filter-options-carddiv .filter-options-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.filter-options-carddiv .filter-options-box div {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}
.filter-options-carddiv .filter-select-one {
  width: 200px;
  /* height: 46px; */
  border-radius: 3px;
}
.filter-options-carddiv .filter-search {
  width: 226px;
  /* height: 46px; */
  border-radius: 3px;
}
.filter-options-carddiv .filter-button {
  height: 40px;
  color: white;
  border-radius: 24px;
  padding: 6px 20px;
  background: #0395ff;
}
.filter-options-carddiv .filter-button:hover {
  background: #007dd7;
}
.filter-options-carddiv .reset-filters {
  color: #ff006b;
  font-size: 14px;
  cursor: pointer;
}
.filter-options-carddiv .reset-filters:hover {
  color: #c80053;
}

.filter-search {
  width: 226px;
  /* height: 46px; */
  border-radius: 3px;
}

/* captcha field */
.captcha-field div div div {
  width: 100% !important;
}
.rc-anchor-normal .rc-anchor-error .rc-anchor-error-msg-container {
  display: none !important;
}
/* 
.g-recaptcha .rc-anchor-error-msg-container {
  display: none;
} */

.rc-anchor-content {
  margin-top: 0px;
}

.g-recaptcha {
  display: flex;
  justify-content: center;
}

.separator {
  border: 1px solid #e8ecf0 !important;
}

.bg-white {
  background-color: #fff !important;
}
.bg-green {
  background-color: #15be53 !important;
}
.more-info-btn {
  background: #ffffff !important;
  border: 1px solid #15be53 !important;
  border-radius: 4px !important;
  color: #15be53 !important;
  text-align: center !important;
  text-transform: none !important;
}

/* The popup bubble styling. */
.popup-bubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the bubble. */
  color: #ffffff;
  background-color: #0d120b;
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 60px;
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popup-bubble-anchor {
  /* Position the div a fixed distance above the tip. */
  position: absolute;
  width: 100%;
  bottom: 8px;
  left: 0;
}

/* This element draws the tip. */
.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #0d120b;
}

/* JavaScript will position this div at the bottom of the popup tip. */
.popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 200px;
}

/* The popup bubble styling. */
.popup-center-bubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the bubble. */

  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 60px;
}

/* JavaScript will position this div at the bottom of the popup tip. */
.popup-center-container {
  cursor: auto;
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 200px;
}

.map-style {
  height: 100% !important;
  width: 100% !important;
}

.property-image {
  height: 240px;
  width: 100%;
}

/* Scroll-Width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Scroll-Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(224, 224, 224);
  border-radius: 8px;
}

/* Scroll-Handle */
::-webkit-scrollbar-thumb {
  background: rgb(202, 202, 202);
  border-radius: 8px;
}

.break-word {
  word-break: break-word;
}

.green-link {
  color: #15be53;
  text-decoration: underline;
}

.navitems .link-active {
  padding-bottom: 10px !important;
  border-bottom: 2px solid #0395ff !important;
}
@media (max-width: 1174.99px) {
  .navitems {
    margin-right: 100px;
  }
}
.left-nav-spacing {
  margin-top: 10px;
  margin-left: 80px;
}
@media (max-width: 1299.99px) {
  .left-nav-spacing {
    margin-left: 40px;
  }
}
@media (max-width: 1024.99px) {
  .left-nav-spacing {
    margin-left: 17px;
  }
}
.right-nav-spacing {
  display: none;
}
@media (min-width: 1174.98px) {
  .right-nav-spacing {
    display: block !important;
  }
  .public_navbar {
    display: none !important;
  }
}
@media (max-width: 1174.98px) {
  .public_navbar {
    display: flex !important;
    flex-direction: row !important;
  }
  .MobAction_btn {
    position: absolute;
    right: 1px;
  }
}
@media (max-width: 1085.99px) {
  .right-nav-spacing {
    margin-right: 0px;
  }
}

.card_inner h5 {
  color: rgba(0, 0, 0, 0.8);
}
.card_inner h4 {
  color: rgba(0, 0, 0, 0.8);
}
.card_inner p {
  color: rgba(0, 0, 0, 0.8);
}

.mobile-table .key {
  font-family: IBM Plex Sans;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: #0395ff;
}

.mobile-table .value {
  font-family: IBM Plex Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #0d120b;
}

.ptr,
.ptr__children {
  height: auto !important;
}
.field-container {
  justify-content: start;
}
.field-container-btn {
  justify-content: end;
}
@media (max-width: 599px) {
  .field-container {
    justify-content: center;
  }
  .field-container-btn {
    justify-content: center;
  }
}

.btn-width {
  width: 75%;
}
@media (min-width: 600px) and (max-width: 740px) {
  .btn-width {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .btn-width {
    width: 62.5%;
  }
}
.zendesk-btn {
  background-color: #005ae1 !important;
  padding: 13.6px !important;
  position: fixed !important;
  right: 16px !important;
  bottom: 16px !important;
}
.zendesk_bottom {
  bottom: 65px !important;
  right: unset !important;
  left: 16px;
}

.zendesk-btn:hover {
  background-color: #005ae1 !important;
}

.grid-card-list-btn {
  display: none !important;
}

.notify_header {
  color: #005b9e !important;
  font-size: 16px;
  line-height: 21px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 25px;
}
.service-agreement {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #0395ff;
}

.company-logo:hover .MuiAvatar-root {
  transform: scale(1.8);
  transition: ease-in-out 0.2s;
}

/* MEDIA QUERY */
@media only screen and (min-width: 2199.98px) {
  .container-fluid {
    max-width: 2100px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1408.98px) {
  .navbar-spacing {
    padding-right: 10px;
  }
}
@media only screen and (max-width: 1408.98px) {
  .navbar-spacing {
    padding-right: 10px;
  }
}

@media only screen and (min-width: 1199.98px) {
  .background_img {
    height: 100vh;
  }

  .px-6 {
    padding: 0 27.5px !important;
  }
  .mx-150 {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media screen and (min-width: 991.98px) and (max-width: 1199.98px) {
  .navbar-spacing {
    padding-left: 50px !important;
  }
}
@media only screen and (max-width: 1199.98px) {
  .mx-150 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .navbar-spacing {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pt-150 {
    padding-top: 80px;
  }

  .pb-150 {
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 1198px) {
  .hide-outer-close {
    display: none !important;
  }
}
@media only screen and (min-width: 1198px) {
  .hide-inner-close {
    display: none !important;
  }
}
@media only screen and (max-width: 991.98px) {
  .mx-150 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .linear_bg {
    padding: 30px;
  }

  .alert_card {
    padding: 20px;
  }

  .background_img {
    height: 100%;
    /* padding: 25% 0; */
  }

  .footer_body {
    padding: 30px 0px;
  }

  .footer_nav a {
    margin: 10px 0;
  }

  .footer_body .separator {
    margin: 30px 0 20px;
  }

  #HeaderNavbar {
    transition: all 0.5s ease;
  }

  #HeaderNavbar .container-fluid {
    justify-content: flex-start;
  }

  #HeaderNavbar #responsive-navbar-nav {
    background-color: #0395ff !important;
    margin-top: 15px;
  }

  .mobile-menu {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }

  .mobile-menu {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0 12px 0 0;
    padding: 0;
  }

  .mobile-menu .navbar-toggler-icon {
    background-image: none;
  }

  .mobile-menu .navbar-toggler-icon {
    margin: 0 auto;
    position: relative;
  }

  .mobile-menu .navbar-toggler-icon {
    width: 24px;
    height: 3px;
    display: block;
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }

  .mobile-menu .navbar-toggler-icon {
    background-color: rgba(0, 0, 0, 0);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }

  .mobile-menu.collapsed .navbar-toggler-icon,
  .mobile-menu.collapsed .navbar-toggler-icon::before,
  .mobile-menu.collapsed .navbar-toggler-icon::after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    background-color: var(--toggle-color, "#fff");
    -webkit-transition: background-color ease;
    transition: background-color ease;
  }

  .mobile-menu .navbar-toggler-icon:before {
    position: absolute;
    content: "";
    width: 24px;
    height: 3px;
    background-color: var(--toggle-color, "#fff");
    display: block;
    margin-top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition-property: margin, -webkit-transform;
    transition-property: margin, transform;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-delay: 0s, 0.2s;
    transition-delay: 0s, 0.2s;
  }

  .mobile-menu .navbar-toggler-icon:after {
    position: absolute;
    content: "";
    width: 24px;
    height: 3px;
    background-color: var(--toggle-color, "#fff");
    display: block;
    margin-top: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition-property: margin, -webkit-transform;
    transition-property: margin, transform;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-delay: 0s, 0.2s;
    transition-delay: 0s, 0.2s;
  }

  .mobile-menu.collapsed .navbar-toggler-icon:before,
  .mobile-menu.collapsed .navbar-toggler-icon:after {
    position: absolute;
    content: "";
    width: 24px;
    height: 3px;
    background-color: var(--toggle-color, "#fff");
    display: block;
    -webkit-transition-property: margin, -webkit-transform;
    transition-property: margin, transform;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-delay: 0.2s, 0s;
    transition-delay: 0.2s, 0s;
  }

  .mobile-menu.collapsed .navbar-toggler-icon:before {
    margin-top: -8px;
  }

  .mobile-menu.collapsed .navbar-toggler-icon:after {
    margin-top: 8px;
  }
  .reposrtResultNavbar {
    background: #3c4c58 !important;
  }
  .navitems a {
    padding: 15px !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .mobile-accordion a {
    padding: 5px !important;
  }
  .MobAction_btn {
    position: relative;
  }

  .MobAction_btn button {
    width: 40px;
    height: 40px;
    padding: 0;
  }

  .map-style {
    height: 318px !important;
  }

  .property-image {
    height: 318px;
  }

  .phone_btn {
    padding: 0 !important;
    box-shadow: none !important;
  }

  .mobile-secions-pt-0 {
    padding-top: 0px !important;
  }

  .mobile-padding-10 {
    padding: 10px !important;
  }

  .content-spacing {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .my_account_btn {
    padding: 0 !important;
    box-shadow: none !important;
  }

  #TryHeaderNavbar {
    transition: all 0.5s ease;
  }

  #TryHeaderNavbar .container-fluid {
    justify-content: flex-start;
  }

  #TryHeaderNavbar #responsive-navbar-nav {
    background-color: #02a8e2 !important;
    margin-top: 15px;
  }

  .notify_header {
    font-size: 12px;
    line-height: 16px;
  }
}

@media only screen and (max-width: 768.98px) {
  body {
    padding-right: 0px !important;
  }

  .pt-150 {
    padding-top: 40px;
  }

  .pb-150 {
    padding-bottom: 40px;
  }

  .linear_bg {
    padding: 30px 15px;
  }

  .navbar-dark .navbar-brand img {
    max-width: 125px;
  }

  .H1 {
    font-size: 30px;
    line-height: 39px;
  }

  .paragraph_text {
    font-size: 20px;
    line-height: 28px;
  }
  .pricing_paragraph_texts {
    font-size: 18px;
    line-height: 26px;
  }

  .MuiBox-root > main {
    width: 100vw;
  }

  .testimonialWrapper .testimonialCard .testimonials .contentWrapper {
    padding: 20px 30px;
  }

  .map-style {
    height: 318px !important;
  }

  .toolbar {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  #adminHeader .supportContact span,
  #adminHeader .supportContact a {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #4c524a;
  }
  .mobile-accordion {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 630.98px) {
  .popup_Btn {
    padding: 10px 24px !important;
    font-size: 13px !important;
  }
}
@media only screen and (max-width: 575.98px) {
  .footer_nav {
    flex-flow: column;
    align-items: center;
  }

  .toolbar {
    z-index: 99999 !important;
    width: 100%;
    display: flex !important;
    position: fixed !important;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: #fff;
  }

  .map-style {
    height: 318px !important;
  }

  .success-btn {
    font-size: 13px !important;
    padding: 8px 20px;
  }

  .g-recaptcha {
    transform: scale(0.83);
  }

  #pagination-container {
    margin-bottom: 75px;
  }

  .mobile-table .MuiChip-root {
    height: 28px;
  }

  .mobile-table .MuiChip-root .MuiChip-label {
    padding: 0 8px;
    font-size: 13px;
  }
}
@media only screen and (max-width: 575.98px) {
  .popup_Btn {
    padding: 8px 22px !important;
    font-size: 13px !important;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
}
@media only screen and (max-width: 370px) {
  .card-list-btn {
    display: none !important;
  }

  .grid-card-list-btn {
    display: block !important;
  }
  .mobile-accordion a {
    padding: 2px !important;
  }
}

.footer_logo {
  width: 20%;
}
.publicNav_logo {
  width: 15%;
}
.signup_logo {
  width: 12%;
}

/* .text {
  width: 430px;
} */
@font-face {
  font-family: "Inspiration";
  src: local("Inspiration"),
    url(./assets/fonts/Inspiration-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "DancingScript";
  src: local("DancingScript"),
    url(./assets/fonts/DancingScript-VariableFont_wght.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Pacifico";
  src: local("Pacifico"),
    url(./assets/fonts/Pacifico-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Sacramento-Regular";
  src: local("Sacramento-Regular"),
    url(./assets/fonts/Sacramento-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Yellowtail-Regular";
  src: local("Yellowtail-Regular"),
    url(./assets/fonts/Yellowtail-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "AlexBrush-Regular";
  src: local("AlexBrush-Regular"),
    url(./assets/fonts/AlexBrush-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Reckless Neue";
  src: local("Reckless Neue"),
    url(./assets/fonts/RecklessNeue-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Mabry Pro";
  src: local("Mabry Pro"),
    url(./assets/fonts/MabryPro-Regular.ttf) format("truetype");
  font-display: swap;
}

.cursor-pointer {
  cursor: pointer !important;
}

.collapsible-row .MuiCollapse-wrapper {
  width: 100% !important;
}

.standard-field .MuiInputBase-root {
  background: transparent;
}

.card-details-drawer-wrapper {
  z-index: 13000001 !important;
}
@media (min-width: 900px) and (max-width: 1440px) {
  .stepper-head {
    margin-left: 0px !important;
    width: 100% !important;
  }
}
