.title-protection-real-estate-investors {
  font-family: "IBM Plex Sans" !important;
  background: #f6f9fc;

  .content___speacing {
    padding-right: 175px !important;
    padding-left: 150px !important;
    @media (max-width: 1470.9px) {
      padding-right: 130px !important;
      padding-left: 100px !important;
    }
    @media (max-width: 1350.9px) {
      padding-right: 70px !important;
      padding-left: 100px !important;
    }
    @media (max-width: 1199.9px) {
      padding-right: 30px !important;
      padding-left: 30px !important;
    }
    @media (max-width: 991.9px) {
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
  }
  .first-section {
    .TTP_EP_logo {
      img {
        position: absolute;
        top: 25px;
      }
    }
    .custom-mt-120 {
      margin-top: 120px !important;
    }

    .section-height {
      height: 628px;
      @media (max-width: 991.99px) {
        height: auto;
      }
    }
    .top-content-spacing {
      @media (max-width: 991.99px) {
        padding-left: 20px !important;
        padding-right: 20px !important;
        margin-top: 40px;
        width: 80vw;
      }
      @media (max-width: 800.99px) {
        width: 90vw;
      }
      @media (max-width: 600.99px) {
        width: 100vw;
        margin-top: 40px;
      }
    }
    .top_heading {
      font-size: 48px;
      font-weight: 600;
      line-height: 57.6px;
      letter-spacing: -0.03em;
      text-align: left;
      margin-top: 100px;
      @media (max-width: 1572.98px) {
        font-size: 45px;
      }
      @media (max-width: 1470px) {
        margin-top: 42px;
        font-size: 38px;
        line-height: 42px;
      }
      @media (max-width: 1340px) {
        font-size: 35px;
        line-height: 44px;
      }
      @media (max-width: 1240px) {
        font-size: 30px;
        line-height: 38px;
      }
      @media (max-width: 1140px) {
        line-height: 39px;
      }
      @media (max-width: 991px) {
        text-align: center;
      }
    }
    .top_para {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      @media (max-width: 1572.98px) {
        font-size: 18px;
        line-height: 25px;
      }
      @media (max-width: 1470.98px) {
        font-size: 20px;
        line-height: 30px;
      }
      @media (max-width: 1240px) {
        font-size: 18px;
        line-height: 25px;
      }
      @media (max-width: 991px) {
        line-height: 27px;
        text-align: center;
      }
    }
    .homepage_logos_v2 {
      img {
        filter: invert(1) brightness(100);
      }
    }
    .homepage_logos_v2 {
      max-width: 100%;
      opacity: 25%;
      gap: 22px;
      -webkit-user-select: none !important;
      @media (max-width: 991px) {
        flex-wrap: wrap !important;
        justify-content: center;
        gap: 40px;
        padding-bottom: 2rem !important;
      }
    }
    .homepage_logos_v2 img {
      height: 22px;
      object-fit: contain;
    }
    .ttp_bg_img {
      width: 675px;
      height: 620px;
      position: absolute;
      right: 0;
      top: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @media (max-width: 1440px) {
        width: 630px;
        height: 580px;
      }
      @media (max-width: 1340px) {
        width: 630px;
        height: 580px;
      }
      @media (max-width: 1265px) {
        width: 600px;
        height: 550px;
      }
      @media (max-width: 1140px) {
        width: 540px;
        height: 500px;
      }
      @media (max-width: 1040px) {
        width: 512px;
        height: 472px;
      }
      @media (max-width: 991px) {
        display: none;
        position: unset;
        width: 100%;
        height: auto;
      }
    }
    .ttp_bg_img-mobile {
      display: none;
      width: 100vw;
      height: auto;
      img {
        border-radius: 10px;
        object-fit: cover;
        margin-top: 45px !important;
      }
      @media (max-width: 991px) {
        display: flex;
        justify-content: center;
      }
    }
    .outer-div {
      max-width: 64%;
      padding-left: 100px;
      margin-top: 0rem;

      @media (max-width: 991px) {
        max-width: 100%;
        padding-left: 0px;
      }
    }
  }
  .ttp_buttons {
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 30px !important;
    font-weight: 600;
    border-radius: 29px;
    text-transform: uppercase;
    color: #000000;
    border: none;
    letter-spacing: 0.05em;
    @media (max-width: 1240.98px) {
      padding: 10px 26px !important;
    }
    @media (max-width: 599.9px) {
      width: 80vw !important;
    }
  }
  .victim_button {
    color: #0155b7 !important;
    border: 1px solid #04152126 !important;
    background: transparent !important;
  }
  .signup_btn {
    background: #15be53 !important;
  }
  .TTP-content {
    @media (max-width: 991.98px) {
      padding: 15px 50px;
    }
    @media (max-width: 699.98px) {
      padding: 15px 5px;
    }
  }
  .header-text,
  .sub_header {
    font-size: 30px;
    font-weight: 600;
    line-height: 39px;
    text-align: left;
    padding: 20px 0px 10px;
    @media (max-width: 1470.98px) {
      padding: 10px 20px;
    }
    @media (max-width: 1300.98px) {
      padding: 10px;
      font-size: 27px;
      line-height: 35px;
    }
    @media (max-width: 991.98px) {
      font-size: 30px;
      line-height: 39px;
    }
    @media (max-width: 599.98px) {
      font-size: 20px;
      line-height: 26px;
    }
  }
  .para-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    padding: 10px 0;
    @media (max-width: 1470.98px) {
      padding: 10px 20px;
    }
    @media (max-width: 1300.98px) {
      padding: 10px;
      font-size: 17px;
    }
    @media (max-width: 991.98px) {
      font-size: 18px;
    }
    @media (max-width: 599.98px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .img-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 991.98px) {
      justify-content: center !important;
    }
    img {
      position: relative;
      border-radius: 10px;
      width: 520px;
      height: 480px;
      box-shadow: 0px 30px 70px -10px #344b5b4a;
      @media (max-width: 1270px) {
        width: auto;
        height: 420px;
      }
      @media (max-width: 991.98px) {
        width: 425px;
        height: 380px;
      }
      @media (max-width: 545.98px) {
        width: 390px;
        height: 350px;
      }
      @media (max-width: 475.98px) {
        width: 315px;
        height: 300px;
      }
    }
  }
  .img-end {
    justify-content: end !important;
    @media (max-width: 991.98px) {
      justify-content: center !important;
    }
  }
  .second-section {
    .container-fluid {
      padding-top: 100px;
      padding-bottom: 100px;
      @media (max-width: 991.98px) {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
  .third-section {
    .row {
      @media (max-width: 991.98px) {
        flex-direction: column-reverse;
      }
    }
    .reverse-column {
      flex-direction: row-reverse;
      @media (max-width: 991.98px) {
        flex-direction: column-reverse !important;
      }
    }
  }
  .fourth-section {
    background: #ebf3fc;
    @media (max-width: 475.98px) {
      background: #f6f9fc;
    }
    img {
      box-shadow: none !important;
    }
    .row {
      @media (max-width: 991.98px) {
        flex-direction: column-reverse;
        padding-bottom: 3rem;
      }
      .bro_mobile {
        display: none !important;
        @media (max-width: 991.98px) {
          display: block !important;
        }
      }
      .hide_for_mobile {
        @media (max-width: 991.98px) {
          display: none !important;
        }
      }
    }
    .reverse-column {
      flex-direction: row-reverse;
      @media (max-width: 991.98px) {
        flex-direction: column-reverse !important;
      }
    }
    .bro {
      height: auto !important;
    }
    .TTP-content-space {
      @media (max-width: 1060.98px) {
        margin-top: 25px;
        margin-bottom: 25px;
      }
    }
    .content-list {
      li {
        padding-left: 5px;
        @media (max-width: 600.98px) {
          padding: 5px;
        }
      }
    }
  }
  .fifth-section {
    background: #f6f9fc;
    @media (max-width: 475.98px) {
      background: #ffffff;
    }
    .row {
      @media (max-width: 991.98px) {
        flex-direction: column-reverse;
      }
    }
    .sub_header {
      text-align: center;
      padding: 0;
    }
    .notify_header {
      margin-bottom: 20px !important;
      @media (max-width: 991.98px) {
        font-size: 16px !important;
      }
      @media (max-width: 599.98px) {
        margin-bottom: 15px !important;
        font-size: 14px !important;
      }
    }
  }
  .story_section {
    margin: 5px 75px;
    @media (max-width: 1200.98px) {
      margin: 5px 25px;
    }
  }
  .protection_container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    margin-top: 100px;
    @media (max-width: 991.98px) {
      margin-top: 25px;
    }
    @media (max-width: 575.98px) {
      padding: 0 !important;
    }
    .protection__bg {
      border-radius: 10px;
      background: #0155b7;
      box-shadow: 0px 30px 70px -10px #344b5b4a;
      margin: 0 60px;
      @media (max-width: 1200.98px) {
        margin: 0 15px;
      }
      @media (max-width: 700.98px) {
        margin: 0 5px;
      }
      @media (max-width: 575.98px) {
        margin: 0;
      }
    }

    .protection__header-text {
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      text-align: center;
      padding: 20px 10px 10px;
      @media (max-width: 1300.98px) {
        font-size: 27px;
        line-height: 35px;
      }
      @media (max-width: 991.98px) {
        // text-align: center;
        font-size: 30px;
        line-height: 39px;
      }
      @media (max-width: 599.98px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
    .protection__block {
      padding: 5% 22%;
      @media (max-width: 1620.98px) {
        padding: 5% 18%;
      }
      @media (max-width: 1440.98px) {
        padding: 5% 14%;
      }
      @media (max-width: 1300.98px) {
        padding: 4% 10%;
      }
      @media (max-width: 991.98px) {
        padding: 5%;
      }
    }
    .protection__para-text {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: center;
      padding: 10px;
      @media (max-width: 1300.98px) {
        font-size: 17px;
      }
      @media (max-width: 991.98px) {
        font-size: 18px;
      }
      @media (max-width: 599.98px) {
        font-size: 16px;
        line-height: 26px;
        // text-align: center;
      }
      a {
        text-decoration-line: underline !important;
        color: #ffffff !important;
      }
    }
    .victim_button {
      background: #ffffff !important;
    }
  }
  .home_title_theft {
    height: auto !important;
    background: #ebf3fc;
    text-align: center;
    padding-bottom: 300px;
    @media (max-width: 440.98px) {
      padding-bottom: 20px;
    }

    .htt_title {
      font-size: 18px;
      font-weight: 600;
      line-height: 23.4px;
      text-align: left;
    }
  }
  .header_speacing {
    padding: 0 27%;
    @media (max-width: 1720.98px) {
      padding: 0 20%;
    }
    @media (max-width: 1500.98px) {
      padding: 0 15%;
    }
    @media (max-width: 1120.98px) {
      padding: 0 10%;
    }
    @media (max-width: 557.98px) {
      padding: 0 1%;
    }
  }
  .risk_points {
    padding: 0 13%;
    @media (max-width: 1720.98px) {
      padding: 0 5%;
    }
    @media (max-width: 1500.98px) {
      padding: 0 2%;
    }
    @media (max-width: 112.98px) {
      padding: 0;
    }
    p {
      @media (max-width: 1470.98px) {
        padding-left: 0;
      }
    }
  }
  .CTA-section {
    background: none;
    text-align: center;
    margin-top: -332px;
    @media (max-width: 991.98px) {
      margin-top: -240px;
    }
    @media (max-width: 521.98px) {
      margin-top: -290px;
    }
    @media (max-width: 483.98px) {
      margin-top: -245px;
    }
    @media (max-width: 440.98px) {
      margin-top: 0;
    }
  }
}
