.lead-gen-content {
  font-family: "IBM Plex Sans" !important;
  .light_bg {
    background-color: #f6f9fc !important;
  }
  .homepage_logos {
    max-width: 100%;
    @media (max-width: 991.98px) {
      justify-content: center;
    }
  }
  .top_section_spacing {
    margin-top: -75px;
    @media (max-width: 991.98px) {
      margin-top: 0px;
      padding: 0 30px;
      text-align: center;
    }
  }
  .lead_form {
    @media (max-width: 991.98px) {
      margin-bottom: -500px !important;
    }
  }
  .second_section {
    @media (max-width: 991.98px) {
      padding-top: 525px !important;
    }
    @media (max-width: 475.98px) {
      .sec1 {
        align-items: start;
        padding-left: 20px;
      }
    }
  }
  .lg_short_second_section {
    display: block !important;
    @media (max-width: 991.98px) {
      display: none !important;
    }
  }
  .lg_section {
    @media (max-width: 991.98px) {
      display: block !important;
    }
  }
  .lg_short_third_section {
    display: none !important;
    @media (max-width: 991.98px) {
      display: block !important;
    }
  }
  .prm_bg_img {
    background-image: url("../../assets/lead_gen.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 10% 0 5%;
  }
  .EP_logo {
    position: absolute;
    width: 190px;
    height: 60px;
    top: 18px;
    left: 50px;
    @media (max-width: 425.98px) {
      left: 20px;
    }
  }
  .main_container {
    padding-left: 30px;
  }
  .header_texts {
    padding: 0 30px;
  }
  .first-section {
    padding-top: 7rem !important;
    // padding-bottom: 7em !important;
    @media (max-width: 991.98px) {
      padding-bottom: 0 !important;
    }

    .main-text {
      font-size: 30px;
      line-height: 35px;
      @media (max-width: 467.98px) {
        font-size: 27px !important;
        line-height: 30px !important;
      }
      @media (max-width: 350px) {
        font-size: 25px !important;

        margin: 5px 0;
      }
    }
  }
  .paragraph_text {
    font-size: 20px;
    line-height: 25px;
    text-align: left;
    @media (max-width: 991.98px) {
      text-align: center;
    }
    @media (max-width: 350px) {
      font-size: 18px !important;
      line-height: 20px !important;
    }
  }
  .logo_columned {
    display: none !important;
  }
  .logo_row {
    display: flex !important;
  }
  .check_img {
    width: 20px !important;
    height: 20px !important;
    margin: 5px;
  }
  .img_2 {
    @media (max-width: 900.98px) {
      margin-left: 10px;
    }
    @media (max-width: 475.98px) {
      margin-left: 5px;
    }
  }
  .sec1_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 5px;
    text-transform: uppercase;
  }
  .property-protected-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .pp_title {
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      text-align: center;
    }
    .pp_caption {
      font-size: 20px;
      font-weight: 300;
      line-height: 30px;
      text-align: center;
    }
    .risk_points {
      padding: 25px 0px 50px 75px;
      @media (max-width: 1050px) {
        padding: 25px 50px 50px;
      }
      @media (max-width: 600px) {
        padding: 25px 30px 50px;
      }
      @media (max-width: 575.98px) {
        text-align: center !important;
      }
      @media (max-width: 350px) {
        padding: 25px 20px 50px;
      }
    }
    .pp_points {
      padding: 25px 100px 100px;
      @media (max-width: 600px) {
        padding: 25px 50px 100px;
      }
      @media (max-width: 575.98px) {
        text-align: center !important;
      }
      @media (max-width: 350px) {
        padding: 25px 20px 100px;
      }
    }
    .protection_title {
      font-size: 18px;
      font-weight: 600;
      line-height: 23.4px;
      text-align: left;

      @media (max-width: 575.98px) {
        font-size: 16px;
        line-height: 21px;
        text-align: center !important;
      }
    }
    .protection_desc {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      text-align: left;
      @media (max-width: 1050px) {
        margin-bottom: 45px;
      }
      @media (max-width: 575.98px) {
        text-align: center !important;
      }
    }
    @media (max-width: 600px) {
      .pp_title {
        font-size: 28px;
        line-height: 37px;
      }
      .pp_caption {
        font-size: 18px;
        line-height: 28px;
      }
      .protection_desc {
        font-size: 16px;
        line-height: 23px;
      }
    }
  }
  .divider {
    margin: 0px 100px;
    @media (max-width: 1050px) {
      margin: 0px 50px;
    }
    @media (max-width: 600px) {
      margin: 0px 30px;
    }
  }
  .free_report-spacing {
    @media (max-width: 1050px) {
      padding: 0px 30px;
    }
    @media (max-width: 350px) {
      padding: 0px 10px;
    }
  }
  .safeGuard {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
  }
  .button {
    padding: 15px 40px 15px 40px;
    border-radius: 29px;
    gap: 10px;
    background: #15be53;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #0d120b;
  }
  .button2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-align: center;
    padding: 15px 40px 15px 40px;
    border-radius: 29px;
    gap: 10px;
    color: #15be53;
    background: #ffffff;
    &:not(.disable):hover {
      background: #ffffff;
    }
  }
  .alert_section {
    padding-bottom: 300px;
  }
  .how_its_works_title {
    font-size: 30px;
    font-weight: 600;
    line-height: 39px;
    text-align: center;
  }
  .how_its_works_texts {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    text-align: center;
  }
  .steps_wrapper {
    .steps {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #0d120b;
      background: rgba(21, 190, 83, 0.3);
      width: 46px;
      height: 46px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 575.98px) {
        text-align: center;
      }
    }
    .number_alignment {
      @media (max-width: 575.98px) {
        text-align: center;
        padding: 0 45%;
      }
    }
    .info_blocks {
      background-color: white !important;
      padding: 30px;
      border-radius: 10px;
      border: 1px solid #dee3ea;
      height: 260px;
      @media (max-width: 572.98px) {
        text-align: center;
      }
    }
    .header_text {
      font-size: 18px;
    }
    .paragraph_text {
      font-size: 18px;
    }
  }
  .card_top {
    background: white !important ;
    max-width: 1075px;
    width: 85%;
    margin-top: -420px !important;
    border-radius: 10px;
    overflow: hidden;
    .title {
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      text-align: left;
      @media (max-width: 575.98px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
    .content {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      text-align: left;
      background: #4c524a;
    }
    @media (max-width: 1180px) {
      width: 85%;
      .content {
        font-size: 17px;
        line-height: 23px;
      }
      .button {
        width: 80%;
      }
    }
  }
  .markgroup_img {
    display: flex;
    align-items: center;
    height: 100% !important;
    margin: 0px;
    img {
      display: flex;
      align-items: stretch;
      object-fit: cover;
    }
  }
  .box_shadow {
    box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
    @media (max-width: 991.98px) {
      margin-top: 36px;
    }
  }
  .card_content {
    text-align: start;
    padding: 65px 0px 20px 70px;
    @media (max-width: 1180px) {
      text-align: center;
      padding: 65px 0px 20px 20px;
    }
  }
  .testimonial-section {
    padding-top: 175px;
  }
  .card_bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
  .how_its_Works_section {
    padding-bottom: 75px;
  }
  .promo_code_outer_section {
    border-radius: 20px;
    border: 1px 0px 0px 0px;
    border: 1px solid rgba(52, 75, 91, 0.29);
    padding: 10px;
    margin: 0 auto;
    text-align: center;
  }
  .promo_code_inner_section {
    background: linear-gradient(266.55deg, #8fda4f -45.17%, #0395ff 109.5%);
    box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
    border-radius: 10px;
    padding: 10px;
    .box_border {
      border-radius: 5px;
      // border: 1px !important;
      // opacity: 0.5;
      border: 1px solid rgba(255, 255, 255, 1);
      border-style: dashed;
      color: #ffffff;
      padding: 60px 20px;
      @media (max-width: 599.98px) {
        padding: 30px 10px 30px;
      }
    }
    .main-text {
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      @media (max-width: 599.98px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
    .paragraph_text {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      padding: 0px 96px;
      @media (max-width: 1336.98px) {
        padding: 0px 50px;
      }
      @media (max-width: 1162.98px) {
        padding: 0px 10px;
      }
      @media (max-width: 599.98px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .bgblack {
    background: #10151d;
  }
  .header_content {
    padding: 10px 100px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    @media (max-width: 767.98px) {
      padding: 10px 50px;
    }
    @media (max-width: 425.98px) {
      padding: 10px;
    }
    .EP_logo {
      width: 220px;
      height: 75px;
      @media (max-width: 1199.98px) {
        margin-left: 10px;
      }
      @media (max-width: 500.98px) {
        img {
          width: 95%;
          height: 95%;
        }
      }
      @media (max-width: 425.98px) {
        left: 20px;
      }
    }
    .contact_section {
      padding: 20px;
      color: white;

      .help_texts {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: end;
      }
      .contact_texts {
        font-size: 17px;
        font-weight: 600;
        line-height: 25px;
        text-align: right;
        a {
          text-decoration: none !important;
        }
      }
      .call_btn {
        background: #0155b7;
        border-radius: 20px;
        width: 40px;
        height: 40px;
        padding: 0;
        top: -5px;
        min-width: unset;
      }
    }
  }
  .call_on {
    font-size: 17px;
    font-weight: 600;
    line-height: 25px;
    text-align: center;

    a {
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: auto;
      color: black;
    }
  }
  .risk-assessment {
    .realStoryWrapper {
      width: 30%;
      @media (max-width: 1335.98px) {
        width: 35%;
      }
      @media (max-width: 991.98px) {
        width: 50%;
      }
      @media (max-width: 900.98px) {
        width: 60%;
      }
      @media (max-width: 645.98px) {
        width: 70%;
      }
      @media (max-width: 545.98px) {
        width: 80%;
      }
    }
  }
  .video_section > .container-fluid {
    display: flex;
    justify-content: center;
    align-items: center;
    .realStoryWrapper {
      width: 700px !important;
      @media (max-width: 799.98px) {
        width: 500px !important;
      }
      @media (max-width: 551.98px) {
        width: auto !important;
      }
      .safeGuard_texts {
        font-weight: 600;
        font-size: 30px;
        line-height: 39px;
        text-align: center;
        @media (max-width: 819.98px) {
          font-size: 28px;
          line-height: 36px;
        }
        @media (max-width: 519.98px) {
          font-size: 22px;
          line-height: 32px;
        }
        @media (max-width: 419.98px) {
          font-size: 18px;
          line-height: 32px;
        }
      }
      .video-wrapper > div,
      iframe {
        width: 700px !important;
        height: 393px !important;
        @media (max-width: 799.98px) {
          width: 500px !important;
          height: 281px !important;
        }
        @media (max-width: 551.98px) {
          width: inherit !important;
          height: 281px !important;
        }
      }
    }
  }
}

.rj_section {
  padding: 80px 0;

  .rj_code_outer_section {
    border-radius: 20px;
    border: 1px 0px 0px 0px;
    border: 0;
    padding: 10px;
    margin: 0 auto;
    text-align: center;
  }
  .container {
    @media (max-width: 991.98px) {
      margin-bottom: 10px;
    }
  }
  .rj_content {
    width: calc(100% - 282px);
    @media (max-width: 991.98px) {
    }
    @media (max-width: 599.98px) {
      margin: 10px;
    }
  }
  .rj_name {
    @media (max-width: 599.98px) {
      font-size: 18px;
      line-height: 28px;
    }
    @media (max-width: 520.98px) {
      font-size: 16px;
      line-height: 28px;
    }
  }
  .rj_person_img {
    display: flex;
    align-items: center;
    margin: 0px !important;
    // padding: 65px 0px 20px 20px;
    img {
      // width: 300px;
      // height: 380px;
      margin: 0px !important;
    }
  }
  .main-text {
    padding-top: 20px;
  }
  .pricing_button {
    width: 75%;
    padding: 15px 40px 15px 40px;
    border-radius: 29px;
    gap: 10px;
    background: #13f82c38 !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #ffffff;
    &:not(.disable):hover {
      background: #15be53;
    }
    @media (max-width: 1391.98px) {
      width: 100% !important;
    }
  }
}

.rj__promo-sec {
  background: linear-gradient(266.55deg, #8fda4f -45.17%, #0395ff 109.5%);
  box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
  border: 1px solid rgba(0, 0, 0, 0.4784313725);
  border-radius: 10px;
  padding: 10px;
  max-width: 980px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  padding: 40px;
}
.rj__promo-sec:before {
  content: "";
  width: 90px;
  height: 152%;
  position: absolute;
  background: #5fc38c;
  z-index: 0;
  transform: rotate(322deg);
  left: 128px;
  top: -77px;
}
.rj_person_img {
  position: relative;
  overflow: hidden;
  border-radius: 100%;
  width: 250px;
  border: 2px dashed #ffffff;
  height: 250px;
}
.rj_person_img > div {
  width: 260px;
  height: 260px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #29a8cf;
  z-index: 99;
  position: relative;
}
.rj_person_img p {
  left: 0;
  right: 0;
  padding: 8px 0;
  color: #ffffff !important;
  font-size: 18px;
  text-decoration: unset;
  background-color: #ffffff91;
  position: absolute;
  bottom: 35px;
  padding-right: 0;
  display: inline-block;
  width: 260px;
  border-radius: 0;
  z-index: 999;
  text-align: center;
}
@media (max-width: 991.98px) {
  .rj_section .rj_content {
    width: 100%;
    position: relative;
  }
  .rj_section .pricing_button {
    margin-bottom: 0 !important;
  }
  .rj__promo-sec:before {
    width: 100%;
    height: 90px;
    transform: unset;
    left: 0;
    top: 122px;
    right: 0;
  }
}
@media (max-width: 767.98px) {
  .rj__promo-sec {
    padding: 12px;
  }
}
