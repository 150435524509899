/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/
.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #8597ab;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.realStoryWrapper {
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;

    & > div {
      height: 100%;
    }

    .realStoriesCard {
      height: 100%;
    }
  }
}

.both-plan-include-box {
  padding: 0 0 0 3rem;
}

.investors_plans {
  p {
    color: #0d120b;
  }
  .accountBoxWrapper {
    padding-top: 15px !important;
    p {
      @media (max-width: 460.98px) {
        padding: 5px !important;
      }
    }
    .activation_text {
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
      text-align: center;
      @media (max-width: 460.98px) {
        font-size: 14px;
      }
    }
    .plan_price {
      font-size: 40px;
      font-weight: 500;
      line-height: 52px;
      text-align: center;
      @media (max-width: 460.98px) {
        font-size: 36px;
      }
    }
    .per_year_price {
      font-size: 30px;
      font-weight: 400;
      line-height: 39px;
      text-align: center;
      @media (max-width: 567.98px) {
        padding: 3px !important;
      }
      @media (max-width: 460.98px) {
        font-size: 26px;
      }
    }
    .price_breakdown_card {
      padding: 20px 20px;
      background: #ebf3fc;
      border-radius: 10px;
    }
    .price_breakdown_text {
      font-size: 14px;
      font-weight: 600;
      line-height: 18.2px;
      letter-spacing: 0.1em;
      text-align: left;
      text-transform: uppercase;
      color: #3362ab;
      padding: 10px 0;
      padding-right: 20px;
    }
    .pricing_cards {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .price_breakdown_left {
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      text-align: left;
      padding: 5px 0;
      padding-right: 20px;
      @media (max-width: 460.98px) {
        font-size: 14px;
      }
      @media (max-width: 390.98px) {
        font-size: 13px;
      }
    }
    .price_breakdown_right {
      font-size: 16px;
      font-weight: 700 !important;
      line-height: 25px;
      text-align: right;
      padding: 5px 0;
      @media (max-width: 460.98px) {
        font-size: 14px;
      }
      @media (max-width: 390.98px) {
        font-size: 13px;
      }
    }
    .discount_section {
      p {
        color: #ff006b !important;
      }
    }
    .total_amount {
      padding: 20px 0px 10px;
      @media (max-width: 460.98px) {
        padding: 10px 0px 10px;
      }

      p {
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        text-decoration-skip-ink: none;
        color: #4c524a !important;
        @media (max-width: 460.98px) {
          font-size: 16px;
        }
        @media (max-width: 390.98px) {
          font-size: 15px;
        }
      }
    }
    .total_amt_divider {
      margin: 20px 5px 0px;
      width: 338px;
      height: 1px;
      background: #00000047;
      @media (max-width: 460.98px) {
        width: auto !important;
      }
    }
  }
  .para {
    margin: 0;
    padding-right: 20px;
    @media (max-width: 567.98px) {
      margin: 0 !important;
      padding: 3px !important;
    }
  }
  .investors_header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;

    .plan_header {
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      color: #0d120b;
      margin: 0;
    }
  }
  .planCard {
    padding: 50px 0px 35px !important;
    @media (max-width: 899.98px) {
      padding: 0px 0px 35px !important;
    }
  }
  .active_plan_button {
    flex-direction: row;
    align-items: flex-start !important;
    font-weight: 600 !important;
    gap: 10px;
    border-radius: 29px !important;
    border: none !important;
    font-size: 16px !important;
    line-height: 20px !important;
    letter-spacing: 0.05em;
    text-transform: uppercase !important;
    background: #15be53 !important;
    color: #0d120b !important;
    padding: 15px 70px !important;
  }

  .investors_plan_features {
    li {
      p {
        max-width: none !important;
      }
    }
  }
}

.points {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .both-plan-include-box {
    padding: 3rem 1rem 0 1rem;

    .text-black {
      font-size: 1.1rem;
    }
  }

  .points {
    display: flex;
    justify-content: flex-start;
    // align-items: flex-start;
  }
}

@media only screen and (max-width: 475px) {
  .both-plan-include-box {
    padding: 1rem 0.5rem 0 0rem;

    .text-black {
      font-size: 0.8rem;
    }
  }
}

.loader_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
  @media (max-width: 635.98px) {
    padding: 0;
  }
  .circular-motion-container {
    display: flex;
    justify-content: center;
    align-items: center;
    //  width: 200px; /* Adjust container size */
    height: 200px;
    position: relative;
  }

  .loader_conatiner {
    .header_texts {
      font-family: IBM Plex Sans;
      font-size: 18px;
      font-weight: 600;
      line-height: 23.4px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin: 10px auto;
    }
    .para_texts {
      font-family: IBM Plex Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin: 0px auto 45px;
    }
    .footer_texts {
      font-family: IBM Plex Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin: 45px auto 15px;
    }
  }

  /* Static Center Circle */
  .static-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 100px; /* Adjust size */
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    img {
      width: 40px; /* Adjust size */
      height: 40px;
    }
  }

  /* Rotating Circle */
  .rotating-circle {
    width: 200px; /* Match container size */
    height: 200px;
    border-radius: 50%;
    animation: rotate 3s linear infinite; /* Smooth rotation */
    background: conic-gradient(
      from 110.14deg at 50% 50%,
      #ffffff 0deg,
      #ffffff 23.66deg,
      rgba(21, 190, 83, 0.4) 190.8deg,
      rgba(3, 149, 255, 0.61) 302.4deg,
      rgba(3, 149, 255, 0.61) 360deg
    );
    border: 3px;
  }

  /* Keyframes for rotation */
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* Outer Circle 1 */
  .outer-circle-1 {
    position: absolute;
    width: 215px;
    height: 215px;
    border-radius: 50%;
    border: 12px solid #0395ff1a;
    animation: pulse 3s ease-in-out infinite;
  }

  /* Outer Circle 2 */
  .outer-circle-2 {
    position: absolute;
    width: 235px;
    height: 235px;
    border-radius: 50%;
    animation: pulse-reverse 3s ease-in-out infinite;
    border: 13px solid #0395ff0a;
  }

  /* Outer Circle 3 */
  .outer-circle-3 {
    position: absolute;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    animation: pulse 3s ease-in-out infinite;
    border: 15px solid #0395ff0a;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0.6;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 0.6;
    }
  }

  @keyframes pulse-reverse {
    0% {
      transform: scale(1);
      opacity: 0.8;
    }
    50% {
      transform: scale(0.9);
      opacity: 0.6;
    }
    100% {
      transform: scale(1);
      opacity: 0.8;
    }
  }

  /* Blinking dot effect using CSS */
  .blinking-dot {
    animation: blink 1s infinite step-start;
  }

  @keyframes blink {
    50% {
      opacity: 0; /* Dot disappears at 50% of the cycle */
    }
  }
}

.Ep_orbit {
  $orbitItemSize: 1.6em;
  $map: (
    ring-0: 2,
    ring-1: 3,
    ring-2: 1,
    ring-3: 1,
  );
  $lastRing: 3;
  .orbit-icon {
    width: 50px;
    height: 50px;
    line-height: $orbitItemSize;
    font-size: 1.2em;
    border-radius: 5px;
    background: rgb(219, 240, 255);
    // background: rgba(255, 255, 255, 0.2);
    color: #fff;
    text-align: center;
    display: block;
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  }
  .orbit-wrap {
    height: 380px;
    list-style: none;
    font-size: 1.3em;

    > li {
      position: absolute;
      left: 75%;
      bottom: 50%;
      transform: translate(-50%, 50%);

      @media (max-width: 1199.99px) {
        left: 50% !important;
        bottom: 12.5% !important;
      }

      &:hover {
        ul {
          border-width: 2px;
          border-color: #fff;
        }
        ~ li ul {
          border-color: rgba(255, 255, 255, 0.2);
          li {
            opacity: 0.4;
          }
        }
      }
    }
    @media (min-width: 1199.99px) {
      height: 450px;
    }
    @media (max-width: 420px) {
      height: 400px !important;
    }
  }
  ul[class^="ring"] {
    @extend %ring;
    transition: all 300ms ease-in-out;
    li {
      @extend %orbiting-object;
      transition: all 300ms ease-in-out;
    }
  }
  @mixin orbit-item($numItems, $numRing) {
    @for $s from 1 through $numItems {
      // Spread items over the ring
      $deg: 380deg / $numItems;

      .ring-#{$numRing} > *:nth-of-type(#{$s}) {
        transform: rotate($s * $deg)
          translate(12.5em - ($numRing * 2.5))
          rotate(-$s * $deg);
      }
    }
  }

  // Render rings
  @for $i from 0 through $lastRing {
    .ring-#{$i} {
      // decrease each ring in size
      width: 25em - ($i * 5);
      height: 25em - ($i * 5);
      animation: clockwiseRotate 35s - ($i * 5) linear infinite;

      img {
        width: 60%;
        height: 60%;
        // animation: counterClockwiseRotate 35s - ($i * 5) linear infinite;
      }
      div {
        animation: counterClockwiseRotate 35s - ($i * 5) linear infinite;
      }
    }

    @include orbit-item(map-get($map, ring-#{$i}), $i);
  }

  %ring {
    // border: dotted 3px rgba(33, 150, 243, 0.8);
    border: 2px dashed rgba(52, 75, 91, 0.2);
    position: relative;
    padding: 0;
    border-radius: 50%;
    list-style: none;
    box-sizing: content-box;
  }

  %orbiting-object {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $orbitItemSize;
    height: $orbitItemSize;
    margin: -$orbitItemSize / 2;
  }

  /*
    center;
  */
  .orbit-center {
    padding: 9px;
    z-index: 5;
    font-size: 2em;
    width: 2.5em;
    height: 2.5em;
    line-height: 1.8em;
    text-align: center;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    box-shadow: 0px 20px 15px 0px rgba(0, 0, 0, 0.05);

    &:hover .orbit-center__icon {
      transform: rotateZ(0deg);
    }
  }
  .orbit-center__icon {
    transform: rotateZ(-360deg);
    transition: all 300ms ease-in-out;
  }
  .orbit-wrap > li.orbit-center:hover ~ li > ul {
    width: 0;
    height: 0;
    * {
      transform: translate(0, 0);
    }
  }

  /* 
  animations 
  */
  @keyframes clockwiseRotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes counterClockwiseRotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
}
